@import "variables";
@import "framework/framework";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/brands";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/solid";

html {
	background: $black;
	font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
	font-family: neue-haas-grotesk-display, sans-serif;
	font-size: 16px;
	line-height: 1;

	@include desktop-sm-down {
		font-size: 15px;
	}

	@include tablet-down {
		font-size: 14px;
	}

	@include phone-down {
		font-size: 13px;
	}

	@include phone-sm-down {
		font-size: 12px;
	}
}

body {
	color: $white;
	position: relative;
}

a {
	color: $white;

	@include hover-focus {
		color: $white;
	}
}

.social{
	@include flex(row,center,center);
	flex-wrap: wrap;
	gap: 15px;
	a{
		width: 40px;
		height: 40px;
		border-radius: 40px;
		@include flex(row,center,center);
		background-color: $white;
		color: $black;
		font-size: 20px;
		i{
			transition: all .4s;
		}
		@include hover-focus{
			i{
				filter: blur(1px);
			}
		}
	}
	@include tablet-up{
		gap: 30px;
		a{
			width: 60px;
			height: 60px;
			font-size: 30px;
		}
	}
}

header {

}

main {
	padding: 20px 0;
	.container{
		@include flex(column,center,center);
		gap: 40px;
		min-height: calc(100vh - 148px);
		.copy{
			max-width: 600px;
			width: 100%;
		}
		.social{
			max-width: 600px;
			@media screen and (max-width:400px) {
				max-width: 250px;
			}
		}
		>a{
			transition: all .4s;
			@include hover-focus{
				transform: scale(1.05);
			}
		}
	}
	@include tablet-up{
		.container{
			min-height: calc(100vh - 131px);
		}
	}
}

footer {
	padding: 20px 0;
	.container{
		.copyright{
			max-width: 550px;
			margin: 0 auto;
			font-size: 12px;
			text-align: center;
			line-height: 1.4;
			p{
				margin-bottom: 0;
				letter-spacing: .5px;
				a{
					@include hover-focus{
						text-decoration: underline;
					}
				}
			}
		}
	}
}